import { Inject, Injectable, inject } from '@angular/core';
import { AmplifyAuth } from '@core/interfaces/auth.interface';
import { environment } from '@environment';
import { AWS_AMPLIFY_AUTH, AuthState, BROWSER_STORAGE, ICognitoUser, JblAuthBaseService, WINDOW } from '@jbl-pip/core-state';
import { catchError, from, map, of, tap } from 'rxjs';
import { UserStateService } from './user-state.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService extends JblAuthBaseService {
	protected signInUrl = environment.signInUrl;
	private readonly userStateService = inject(UserStateService);

	constructor(
		@Inject(BROWSER_STORAGE) public override storage: Storage,
		@Inject(AWS_AMPLIFY_AUTH) protected override auth: AmplifyAuth,
		@Inject(WINDOW) protected override window: Window
	) {
		super(storage, auth, window);
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public init(): void {}

	getCognitoUser() {
		return from(this.auth.currentUserPoolUser()).pipe(
			catchError(() => of(null as unknown as ICognitoUser)),
			map((cognitoUser: ICognitoUser) => this.mapUser(cognitoUser)),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			tap((user: any) => {
				if (!user) {
					console.log('No cognito user');
					this.userStateService.setAuthState(AuthState.SIGNED_OUT);
				} else {
					this.userStateService.setUser(user);
					this.userStateService.setAuthState(AuthState.SIGNED_IN);
				}
			})
		);
	}
}
